.carrousel {
    overflow: hidden;
}

.carrousel__list {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: var(--spacing-2x) calc(var(--spacing-unit) * -1) 0;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

/* spacer needed because margin-right does not work in combination with overflow: scroll and display flex */
.carrousel__list:after {
    content: '';
    flex-basis: var(--spacing-unit);
    flex-shrink: 0;
}

.carrousel__item {
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-weight: bold;
    height: 13.64rem;
    margin-right: 0.5rem;
    padding: var(--spacing-unit) .5rem;
    text-align: center;
    width: 10.42rem;
}

.carrousel__item:first-child {
    margin-left: var(--spacing-unit);
}

.carrousel__item:last-child {
    margin-right: 0;
}

.carrousel__item--primary {
    background: var(--primary);
}

.carrousel__item--tertiary {
    background: var(--tertiary);
}

.carrousel__item--warning {
    background: var(--warning);
}

.carrousel__item-link {
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    width: 100%;
}

.carrousel__item-text {
    margin-top: auto;
    margin-bottom: auto;
    line-height: 1.375em;
}

.carrousel__item-more-button {
    width: 1.85rem;
    margin-top: auto;
    align-self: center;
}

.carrousel__item .icon:first-child {
    height: 5rem;
    flex-shrink: 0;
}
