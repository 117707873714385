.chat-widget {
    margin-bottom: var(--spacing-2x);
    margin-top: var(--spacing-1x);
    --call-us-form-header-background:var(--tertiary);
    --call-us-header-text-color:#ffffff;
}

/* mimic call-us-phone web component style: */
.chat-widget__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: var(--tertiary);
    color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.chat-widget__icon {
    width: 80%;
}

.chat-widget__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: #000;
    opacity: 0;
}
.chat-widget:focus .chat-widget__overlay,
.chat-widget:hover .chat-widget__overlay {
    opacity: 0.2;
}
