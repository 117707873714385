.question + .question {
    margin-top: 1em;
}

.question__bubble p {
    margin: 0;
}

.question__answers {
    align-items: center;
    animation: fade-in 0.2s forwards 1.6s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transform: scale(0);
}

.question:first-of-type > .question__answers {
    animation-delay: 2.4s;
}

.question__answers--inline {
    flex-direction: row;
}

@media (max-width: 410px) {
    .question__answers--inline {
        flex-direction: column;
    }
}

.question__answers--fill {
    flex-direction: row;
    flex-wrap: wrap;
}

.question--answered input:not(:checked) + label {
    display:none;
}

.question--answered input:checked + label {
    background: var(--primary);
    color: #fff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
}
