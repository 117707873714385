@import '~@/components/answer/answer.css';
@import '~@/components/carrousel/carrousel.css';
@import '~@/components/chat-widget/chat-widget.css';
@import '~@/components/core-styling/core-styling.css';
@import '~@/components/error-overlay/error-overlay.css';
@import '~@/components/icon/icon.css';
@import '~@/components/instruction-item/instruction-item.css';
@import '~@/components/instructions/instructions.css';
@import '~@/components/modal/modal.css';
@import '~@/components/new-scan-modal/new-scan-modal.css';
@import '~@/components/question/question.css';
@import '~@/components/side-effects/side-effects.css';
@import '~@/components/timeout-modal/timeout-modal.css';
@import '~@/components/browse-happy/browse-happy.css';
@import '~@/components/warning/warning.css';

.welcome-header {
    font: var(--l);
    font-weight: bold;
    margin: 0;
}

.welcome-text {
    font: var(--l);
    font-weight: lighter;
    margin-top: 0
}

.site {
    font: var(--xs);
    font-weight: bold;
    margin-bottom: .5rem;
}

ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.when-done {
    display: none;
}

.done .when-done {
    display: block;
}

.questions-form {
    position: relative;
    margin-bottom: 1rem;
}
.form-anchor {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 1px;
    height: 1px;
    translate: -50% 1rem;
    border-radius: 50%;
    background-color: white;
    z-index: -10;
}

.done .finished-container {
    animation: fade-in 0.2s forwards ease-in-out;
    opacity: 0;
}

.back-to-home {
    position: fixed;
    right: var(--spacing-2x);
    top: var(--spacing-2x);
    z-index: 5;
}

.back-to-home svg {
    fill: var(--text);
    height: 2.4rem;
    width: 2.4rem;
}

.medicine__footer {
    color: #5a5a5a;
    padding: var(--spacing-2x) var(--spacing-2x) 0;
    text-align: center;
}

.medicine__footer a {
    color: var(--tertiary);
    display: block;
    font: var(--l);
    margin-bottom: var(--spacing-2x);
}

.medicine__footer-nav a {
    color: inherit;
    display: inline;
    font: var(--xs);
    text-decoration: none;
}

.medicine__footer-nav a:after {
    display: inline-block;
    margin-left: .5rem;
    margin-right: .5rem;
    content: '|';
}

.medicine__footer-nav a:last-child:after {
    content: none;
}

.medicine__copyright {
    font: var(--xxs);
    margin-top: var(--spacing-2x);
}

.medicine__copyright p {
    line-height: 1;
    margin: 0;
}

.medicine__copyright p:first-of-type {
    margin-bottom: 8px;
}
