.icon {
    display: inline-flex;
    vertical-align: middle;
    align-content: center;
    justify-content: center;
    fill: currentColor;
}

.icon svg {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.icon svg > .bijsluiter_svg__transparent {
    fill: transparent;
}
