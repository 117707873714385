.answer {
    position: relative;
    font: var(--s);
}

.answer--inline {
    margin-right: 0.5em;
}

.answer--inline:last-of-type {
    margin-right: 0;
}

@media (max-width: 410px) {
    .answer--inline {
        margin-right: 0;
    }
}

.answer--fill {
    margin-bottom: 0.375em;
    margin-right: 0.375em;
}

.question--answered .answer--fill {
    margin: 0;
}

.answer input {
    opacity: 0;
    position: absolute;
}

.answer label {
    border-radius: 25px;
    border: 2px solid var(--primary);
    color: var(--primary);
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.5em;
    padding: 0.8125em 0;
    text-align: center;
    width: 12.5em;
}

.answer--fill label {
    width: fit-content;
    padding: 0.8125em 1.125em;
}

.question--answered .answer label {
    width: fit-content;
    margin: 0;
    padding: 0.8125em 1.125em;
}
