.warning {
    margin-bottom: 0.5em;
}

.warning__button {
    -webkit-appearance: none;
    align-items: center;
    border-radius: 25px;
    border: 0;
    color: #ffffff;
    display: flex;
    font: inherit;
    font-weight: bold;
    padding: 0.875em 1em;
    text-align: left;
    width: 100%;
}

.warning__button > * {
    pointer-events: none;
}

.warning[data-color="neutral"] .warning__button {
    background-color: var(--secondary);
    color: var(--text);
    font-weight: normal;
}

.warning[data-color="neutral"] .warning__button .icon svg {
    fill: var(--text);
}

.warning[data-color="warning"] .warning__button {
    background-color: var(--warning);
}

.warning[data-color="error"] .warning__button {
    background-color: var(--error);
}

.warning__button .icon {
    height: 50px;
    width: 50px;
}

.warning__button .icon svg {
    fill: #ffffff;
}

.warning__text {
    margin: 0 1em;
    width: 100%;
}