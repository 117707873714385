ol.side-effects__list li:before {
    content: none;
}

ol.side-effects__list li {
    margin-bottom: var(--spacing-2x);
    display: flex;
    flex-direction: row;
}

ol.side-effects__list .icon {
    color: var(--warning);
    width: 4.5rem;
    height: 4.5rem;
    flex-shrink: 0;
    margin-left: -1.25em;
    margin-right: var(--spacing-unit);
}

.side-effects__item-content > strong + p {
    margin: 0;
}

.rich-text .side-effects__item-content > p {
    display: block;
}

