.instructions {
    -webkit-font-smoothing: antialiased;
    background: var(--tertiary);
    border-radius: 25px;
    border-top-left-radius: 5px;
    color: #fff;
    margin-bottom: var(--spacing-3x);
    padding: var(--spacing-2x) var(--spacing-2x) var(--spacing-unit) var(--spacing-2x);
}

.instructions__list {
    padding: 0;
    list-style-type: none;
    margin: 0;
    margin: var(--spacing-2x) 0;
}

.instructions__list li {
    margin-bottom: .5em;
    line-height: 1.57;
}

.instructions-modal__list .icon {
    color: var(--tertiary);
}

.instructions__more-button {
    margin: 0 auto;
    width: 1.85rem;
    display: block;
}

.instructions__header {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-2x);
}

.instructions__img {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 4px 4px 4px rgba(0,0,0,.4);
    height: 2.85rem;
    margin-right: var(--spacing-unit);
    object-fit: cover;
    object-position: center;
    width: 2.85rem;
}

.instructions__name {
    font: var(--l);
    font-weight: bold;
    margin: 0;
}

.instructions__ingredient {
    font: var(--xs);
    font-weight: bold;
    margin: 0;
}

.instructions-modal__header {
    color: #fff;
    background: var(--tertiary-lg);
    -webkit-font-smoothing: antialiased;
}

.instructions-modal__header .container {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

@media (kiosk) {
    .instructions-modal__header {
        padding: var(--spacing-3x) var(--spacing-2x) var(--spacing-unit) var(--spacing-2x);
    }

    .instructions-modal__list li {
        margin: 0 var(--spacing-2x) var(--spacing-2x) calc(var(--spacing-2x) - .6em);
    }
}

@media (web) {
    .instructions-modal__header {
        padding: var(--spacing-mobile);
    }

    .instructions-modal__list li {
        margin: 0 var(--spacing-mobile) var(--spacing-mobile) calc(var(--spacing-mobile) - .6em);
    }
}

.instructions-modal__header-text {
    width: calc(100% - 30px);
    font: var(--xl);
    font-weight: bold;
    margin: 0;
}

.instructions-modal__header-info {
    flex-grow: 1;
}

.instructions-modal__info-list dt {
    float: left;
    font-weight: bold;
}

.instructions-modal__info-list dt:after {
    content: ':';
    margin-right: .3em;
}

.instructions-modal__info-list dd {
    margin: 0;
    margin-bottom: .5rem;
}
