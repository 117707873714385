.new-scan-modal {
    background: rgba(0,0,0,.75);
    backdrop-filter: blur(7px);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    padding: 0 var(--spacing-4x);
}

.new-scan-modal__icon {
    width: 7rem;
    height: 7rem;
}

.new-scan-modal__header {
    font: var(--l);
    font-weight: bold;
    margin: 0;
}

.new-scan-modal__text {
    font: var(--l);
    font-weight: lighter;
    margin-top: 0;
    margin-bottom: var(--spacing-2x);
}

.new-scan-modal__footer {
    display: flex;
    flex-direction: row;
}

.new-scan-modal__answer {
    border-radius: 25px;
    border: 2px solid currentColor;
    color: #fff;
    display: inline-block;
    font: var(--s);
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-left: var(--spacing-unit);
    margin-right: var(--spacing-unit);
    min-width: 4.5em;
    padding: 0.8125em;
    text-align: center;
}
