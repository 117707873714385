.modal {
    -webkit-overflow-scrolling: touch;
    animation: fade-in 0.2s forwards ease-in-out;
    border: 0;
    flex-direction: column;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: 100vh;
    max-width: 100vw;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    top: 0;
    transform-origin: center center;
    transform: scale(0);
    width: 100vw;
}

.modal[open] {
    display: flex;
}

.modal embed {
    display: block;
    height: 100vh;
}

.modal__header {
    -webkit-font-smoothing: antialiased;
    color: #fff;
    display: flex;
    fill: currentColor;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: .5rem;
}

.modal--primary .modal__header {
    background: var(--primary-lg);
}

.modal--neutral .modal__header {
    background: var(--neutral-lg);
    color: var(--text);
}

.modal--error .modal__header {
    background: var(--error-lg);
}

.modal--warning .modal__header {
    background: var(--warning-lg);
}

.modal--tertiary .modal__header {
    background: var(--tertiary-lg);
}

.modal__header-top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.1rem;
}

.modal__header-top > .icon {
    width: 85px;
    height: 85px;
    margin-bottom: 1.45rem;
}

.modal__header-text {
    font: var(--xl);
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.modal__header-bottom {
    margin: 0;
    font: var(--xs);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal__header-bottom-right {
    margin-left: auto;
}

.modal__header-bottom .icon {
    width: 2.4rem;
    height: 2.4rem;
}

.modal__close-button {
    width: 2.57rem;
    height: 2.57rem;
    position: absolute;
    right: var(--spacing-2x);
    top: var(--spacing-2x);
    color: #fff;
}

.modal--neutral .modal__close-button {
    color: var(--text);
}

@media (kiosk) {
    .modal__header {
        padding: var(--spacing-2x);
        position: sticky;
        top: 0;
    }

    .modal__body, .modal__footer {
        padding: var(--spacing-2x);
    }

    .modal__close-button {
        right: var(--spacing-2x);
        top: var(--spacing-2x);
    }
}

@media (web) {
    .modal__header {
        padding: var(--spacing-mobile);
    }

    .modal__header-text {
        max-width: 50rem;
    }

    .modal__body, .modal__footer {
        padding: var(--spacing-mobile);
    }

    .modal__close-button {
        right: var(--spacing-mobile);
        top: var(--spacing-mobile);
    }
}

.bijsluiter-modal svg {
    fill: var(--text);
}

.modal__footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.modal__footer .chat-widget {
    margin: 0 auto;
}

.modal--primary .chat-widget {
    --call-us-form-header-background: var(--primary);
}

.modal--neutral .chat-widget {
    --call-us-form-header-background: var(--neutral);
}

.modal--warning .chat-widget {
    --call-us-form-header-background: var(--warning);
}

.modal--error .chat-widget {
    --call-us-form-header-background: var(--error);
}

.modal--tertiary .chat-widget {
    --call-us-form-header-background: var(--tertiary);
}

.chat-widget:not([hidden]) {
    width: 54px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
