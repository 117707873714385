.instruction-item {
    color: currentColor;
    fill: currentColor;
    font: var(--s);
    display: flex;
    flex-direction: row;
}

.instruction-item[hidden] {
    display: none;
}

.instruction-item__header {
    font-weight: bold;
    margin-bottom: 0.3rem;
    display: block;
}

.instruction-item .icon {
    width: 2.5rem;
    margin-top: -0.7rem;
    margin-right: 0.75rem;
    vertical-align: middle;
    align-self: flex-start;
    flex-shrink: 0;
}

.instruction-item + .instruction-item {
    margin-top: var(--spacing-unit);
}
