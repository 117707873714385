.error-overlay h2,
.error-overlay p {
    font: var(--xxl);
    margin: 0;
}

.error-overlay h2 {
    font-weight: bold;
}

.error-overlay .icon:last-child {
    height: 9.5rem;
    position: relative;
    width: 9.5rem;
}

.error-overlay .icon:last-child::after {
    animation: scan 3s infinite ease-in-out;
    background-color: var(--error);
    content: '';
    height: 3.75rem;
    left: 1.95rem;
    position: absolute;
    top: 2.90rem;
    width: 5px;
    z-index: var(--layer-raised);
}

.error-overlay {
    align-items: center;
    background-color: var(--error-modal);
    display: none;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    left: 0;
    padding: 9% 5% 5%;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--layer-raised);
    color: #fff;
    text-align: center;
}

.error-overlay:not([hidden]) {
    display: flex;
}

.error-overlay div > .icon {
    margin-bottom: var(--spacing-4x);
    height: 10.5rem;
    width: 10.5rem;
}

@keyframes scan {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5.325rem);
    }
}